/**
 * home header每一项游戏 piwik
 * @param {*} gameCatCode  游戏类型
 * @param {*} providerCode  游戏code
 * @param {*} actionType 
 */
export function SetPiwikData(gameCatCode, providerCode, actionType) {
    console.log("🚀 ~ file: piwikData.js:8 ~ SetPiwikData ~ gameCatCode:", gameCatCode)
    if (gameCatCode === "Sportsbook") {
        if (providerCode === "VTG") {
            Pushgtagdata("Home", `Go to ${providerCode} Lobby`, `Home_${gameCatCode}_C_${providerCode}​`);
        } else {
            Pushgtagdata("Home", `Launch ${providerCode}`, `Home_${gameCatCode}_C_${providerCode}​`);
        }
    } else if (gameCatCode === "InstantGames") {
        if (providerCode === "AVIATOR") {
            Pushgtagdata("Home", "Launch HotGame Aviator", `Home_${gameCatCode}_C_HotGame​`,
                "",
                [
                    {customVariableKey: `Home_${gameCatCode}_C_HotGame_GameName`,
                    customVariableValue: "Aviator"},
                ]
             );
        } else {
            Pushgtagdata("Home", `Go to ${providerCode} Lobby`, `Home_${gameCatCode}_C_${providerCode}​`);
        }
    } else {
        if (gameCatCode.toLowerCase() === "esports"){
            Pushgtagdata("Home", `Launch ${providerCode}`, `Home_${gameCatCode}_C_${providerCode}​`);
        } else {
            Pushgtagdata("Home", `Go to ${providerCode} Lobby`, `Home_${gameCatCode}_C_${providerCode}​`);
        }
    }
}

//Header 点击菜单中的每个游戏的 piwik
export const HeaderGameNavPiwik = (code) => {
    Pushgtagdata("Home_TopNav", `Go to ${code} Lobby`, `Home_TopNav_C_${code}`)
}


//Header 点击菜单中各个游戏的more按钮 piwik
export const HeaderGameNavMoreBtnPiwik = (code) => {
    Pushgtagdata("Home", `View ${code} Listing`, `Home_C_${code}`)
}

/**
 * 游戏列表内选择左边的游戏供应商按钮 piwik
 * @param {*} providerCode  游戏
 * @param {*} gameType 筛选类型
 */
export const gameLobbyFilterVenderPiwik =(providerCode,gameType) => {
    console.log("🚀 ~ file: piwikData.js:779 ~ gameLobbyFilterVenderPiwik ~ providerCode:", providerCode,",",gameType)
    let vendor = gameType;
    if (gameType === ""){
        vendor = "All";
    } else if (gameType === "AVIATOR"){
        vendor = "HotGame";
    }
    Pushgtagdata(
        `${providerCode}_Lobby`, 
        "Filter Vendor​", 
        `${providerCode}_Lobby_C_${vendor}​`,
    )
}

//游戏大厅内点击游戏banner
export const gameLobbyPageBannerPiwik =(gameCatCode,providerCode) => {
    Pushgtagdata(
        `${gameCatCode}_Listing​`,
        `Launch ${providerCode}`, 
        `${gameCatCode}_Listing_C_${providerCode}`,
    )
}
/**
 * 游戏大厅内立即游戏按钮 piwik
 * @param {*} gameCatCode 
 * @param {*} providerCode 
 */
export const gameLobbyOpenGamePiwik =(gameCatCode, providerCode)=> {
    console.log("🚀 ~ file: piwikData.js:805 ~ gameLobbyOpenGamePiwik ~ gameCatCode, providerCode:", gameCatCode, providerCode)
    Pushgtagdata(`${gameCatCode}_Listing​`,`Launch ${providerCode}​`, `${gameCatCode}_Listing_C_${providerCode}_Commercial​`)
}

/**
 * 游戏列表页（无搜索游戏功能页面、推荐游戏栏）立即游戏按钮 piwik
 * @param {*} item 
 * @param {*} gameCatCode //Slots
 */
export const gameListOpenGamePiwik =(item,gameCatCode) => {
    console.log("🚀 ~ file: piwikData.js:826 ~ gameListOpenGamePiwik ~ item:", item,gameCatCode)
    Pushgtagdata(
        `${gameCatCode}_Listing`,
        `Launch Game ${item.gameName}` ,
        `${gameCatCode}_Listing_C_Recommended_${item.provider}_Game​`,
        "",
        [
            {customVariableKey: `${gameCatCode}_Listing_C_Recommended_${item.provider}_GameName`,
            customVariableValue: item.gameName},
        ]
    );
}
/**
 * 游戏列表页（可搜索游戏页）立即开始游戏Piwik
 * @param {*} item 
 */
export const  gameListAtFilterOpenGamePiwik =(item,Routerpath) => {
    console.log("🚀 ~ gameListAtFilterOpenGamePiwik ~ item:", item)
    Pushgtagdata(
        `${Routerpath}_Lobby`,
        `Launch Game ${item.gameName}`,
        `${Routerpath}_Lobby_C_${item.provider}_Game​`,
        "",
        [
            {customVariableKey: `${Routerpath}_Lobby_C_${item.provider}_GameName`,
            customVariableValue: item.gameName},
        ]
    );
}

/**
 * 游戏列表页面（有搜索游戏功能页） title 和 url 的piwik
 * @param {*} item 
 */
export const gameLobbyPageTrackingPiwik =(item)=> {
    console.log("🚀 ~ gameLobbyPageTrackingPiwik ~ item:", item)
    switch (item){
        case "LiveCasino":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`live_dealer_lobby​​`,`LiveDealer​ Lobby​​`);
            break;
        case "InstantGames":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`instantgames_lobby​`,`InstantGames Lobby​​​​`);
            break;
        case "Sportsbook":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`v2_sports_lobby​​​`,`V2 Sports Lobby​​​`);
            break;
        case "P2P":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`p2p_lobby​​​​`,`P2P​ Lobby​​​​​​`);
            break;
        case "Slot":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`slotfishing_lobby​​`,`SlotFishing​ Lobby​​​​​​`);
            break;
        case "KenoLottery":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`lottery_lottery​`,`Lottery​ Lottery​`);
            break;
        default:
            break;
    }
}

/**
 * 游戏列表页面（无搜索游戏功能页） title 和 url 的piwik
 * esports 没有列表页页面
 * sports 只有v2体育有列表页面
 * @param {*} item 
 */
 export const gameListPageTrackingPiwik =(item)=> {
    console.log("🚀 ~ gameListPageTrackingPiwik ~ item:", item)
    switch (item){
        case "ESports":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`esports​_listing​​​`,`Esports Listing​​​`);
            break;
        case "LiveCasino":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`live_dealer_listing​​`,`LiveDealer Listing​​`);
            break;
        case "InstantGames":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`instantgames_lobby​​`,`InstantGames Lobby​​`);
            break;
        case "Sportsbook":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`sports_​listing​`,`Sports Listing​​​`);
            break;
        case "P2P":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`p2p_listing​​`,`P2P​ Listing​​​`);
            break;
        case "Slot":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`slotfishing_listing​​`,`SlotFishing​ Listing​​​​`);
            break;
        case "KenoLottery":
            global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(`lottery_listing​​`,`Lottery​ Listing​​​​`);
            break;
        default:
            break;
    }
}

/**
 * 游戏列表页点击more piwik
 */
 export const gameListPageCheckMorePiwik =(path)=> {
    Pushgtagdata(
        `${path}_Listing​`,
        "Go to Recommended Game Listing​" ,
        `${path}_Listing_C_Recommended_More​`
    );
}

/*游戏列表页、游戏平台列表 PIWIK
* @param {*} gameCatCode  游戏类型code
* @param {*} providerCode  子类code
* @param {*} providerId    子类id
*/
export const gameListPageGamesPlatform =(gameCatCode, providerCode, providerId)=> {
    if (providerCode === "AVIATOR") {
        Pushgtagdata(
            `${gameCatCode}_Listing​`,
            "Launch HotGame Aviator" ,
            `${gameCatCode}_Listing_C_HotGame_SPR_Game`,
            "",
            [
                {customVariableKey: `${gameCatCode}_Listing_C_HotGame_SPR_GameName`,
                customVariableValue: "Aviator"},
            ]
        );
    } else {
        Pushgtagdata(
            `${gameCatCode}_Listing​`,
            `Go to ${providerCode} Lobby` ,
            `${gameCatCode}_Listing_C_${providerCode}`,
        );
    }
}

/*游戏列表页、（无搜索游戏功能页）游戏类型 PIWIK
* @param {*} gameCatCode  游戏类型code
* @param {*} categoryType  类型
* @returns 
*/
export const gameListPageGamesTypePiwik =(gameCatCode,categoryType)=>{
    Pushgtagdata(
        `${gameCatCode}_Lobby​`,
        "Filter Game",
        `${gameCatCode}_Lobby_C_GameType`,
        "",
        [
            {customVariableKey: `${gameCatCode}_Lobby_C_GameType`,
            customVariableValue: categoryType},
        ]
    );
}

/**
 * 游戏列表页（可搜索游戏页），游戏类型 Piwik
 * @param {*} gameCatCode 
 * @param {*} categoryType 
 */
export const gameListProductPageTypePiwik =(gameCatCode, categoryType) =>{
    console.log("🚀 ~ categoryType:", categoryType)
    Pushgtagdata(
        `${gameCatCode}_Lobby`,
        "Filter Game​",
        `${gameCatCode}_Lobby_C_GameType​`,
        "",
        [
            {customVariableKey: `${gameCatCode}_Lobby_C_GameType`,
            customVariableValue: categoryType},
        ]
    );
}