import React from "react";
import { Row, Col, Input, Button } from "antd";
import Notice from "./Notice";
import Login from "../UserSign/Login";
export default class NotLogged extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
        };
    }
    componentDidMount() {
        /* 
            HasLogged => 設置此參數來判斷 showModal 已設置完成，避免開啟遊戲確認餘額時 global.showDialog 為空
            NotLogged => 直接設為 true
        */
        this.props.setIsWalletSetupComplete && this.props.setIsWalletSetupComplete(true);
    }
    AlreadLogin = () => {
        this.props.AlreadLogin();
    };
    render() {
        const { UserName, Password } = this.state;
        return (
            <div className="common-distance-wrap">
                <div
                    className={`common-distance tlc-sign-header ${
                        this.props.smallHeader === "zoom-out" && "hidden"
                    }`}
                >
                    <Row>
                        <Col span={12} className="tlc-notice-wrapper">
                            <Notice />
                        </Col>
                        <Login
                            home={true}
                            AlreadLogin={() => this.AlreadLogin()}
                        />
                    </Row>
                </div>
            </div>
        );
    }
}
