import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const Loader = ({ 
    src = "/vn/img/icons/loading.gif", 
    width, height, loadingText, visible, 
    CustomModalClass = "", customLoaderClass, 
    customLoaderTxtClass }) => {
    return (
        <Modal
        className={`walletLoading ${CustomModalClass}`}
        visible={visible}
        footer={null}
        closeIcon={() => null}
        >
            <div className={`loaderWrapper ${customLoaderClass}`}>
                <img
                    src={src}
                    style={{ width: width, height: height }}
                    alt="loading"
                />
                <p
                    className={`loaderText ${customLoaderTxtClass}`}
                >
                    {loadingText}
                </p>
            </div>
        </Modal>
    );
};

Loader.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    loadingText: PropTypes.string,
};

Loader.defaultProps = {
    width: "40px",
    height: "40px",
    loadingText: "Loading...",
};

export default Loader;